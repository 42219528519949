<template>
	<div class="row patient_chart third-party-main">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 billing_module_table analysis_report hr-analysis_report-main">
            <div class="header_boxes">
                <div class="hr-analysis-select">
                    <div class="selact_dropdown d-inline-block">
                        <CustomDropDown :options="this.provider_list" :defaultSelectedClass="defaultSelectedClass"
                            :initialValue="this.form.physician_text" v-model="form.user_id"
                            @item-selected="handleItemSelected" fieldName="user_id">
                        </CustomDropDown>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th class="hover_unset w-75px"></th>
                            <th scope="col">YEAR </th>
                            <th scope="col">MONTH</th>
                            <th scope="col">USER </th>
                            <th scope="col">REPORT NAME </th>
                            <th scope="col" class="hover_unset" style="width: calc(var(--scale-ratio) * 132px);"></th>
                        </tr>
                    </thead>
                    <tbody v-if="this.reportData.length > 0">
                        <tr v-for="(item,index) in this.reportData" :key="index">
                            <td></td>
                            <td>{{ item.year }}</td>
                            <td>{{ item.month }}</td>
                            <td>{{ item.user_name }}</td>
                            <td>{{ item.report_name }}</td>
                            <td>
                                <span class="popup_link" @click="download(item.file_path)">Download</span>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
	</div>
</template>

<script>
import sidebar from './sidebar.vue'
import CustomDropDown from '../base/formFields/CustomDropDown.vue';
import $ from "jquery";
import axios from "@/scripts/axios.js";

export default {
    data() {
        return {
            provider_list:[],
            tagProviderLabel:"Select Provider(s)",
            isUnselectAll:false,
            reportData:[],
            form:{
                user_id:null,
                physician_text:"Select Users",
            },
            defaultSelectedClass:null
        }
    },
    components: {
        sidebar,
        CustomDropDown
    },
    methods: {
        getProviderList() {
				axios.post("json_list/provider")
					.then((response) => {
                        const providerTempData = [];
                        $.each(response.data.data, function (key, detail) {
                                providerTempData.push({
                                    value: detail.user_id,
                                    title: detail.role_name +" | "+ detail.full_name,
                                })
                         })
						this.provider_list = providerTempData;
				}).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })

        },
        selected_provider(result){
            this.form.provider_ids = result.filter(item => item.checked).map(item => item.id);
            if (this.form.provider_ids.length == 1) {
                this.tagProviderLabel = `${this.form.provider_ids.length } Provider Selected`;
            } else if (this.form.provider_ids.length > 1){
                this.tagProviderLabel = `${this.form.provider_ids.length } Providers Selected`;
            } else {
                this.tagProviderLabel = "Select Provider(s)";
            }
        },
        getReportData(){
            this.$store.state.loader = true;
            axios.post('admin/hr-analysis-reports', {'filter_user_id':this.form.user_id})
                    .then((response) => {
                        this.$store.state.loader = false;
                        if (response.status == 200) {
                            this.reportData = response.data.data
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
        },
        handleItemSelected({ fieldName, item }) {
               this.form[fieldName] = item.value;
               if (fieldName == 'user_id') {
                    this.form.physician_text = item.title
                    this.defaultSelectedClass = 'active'
                    this.getReportData()
               }
        },
        async download(fileName){
            this.$store.state.loader = true;
            axios.get(`admin/hr-analysis-download/${fileName}`)
                    .then((response) => {
                        this.$store.state.loader = false;
                        if (response.status == 200) {
                            const url = response.data.url;
                            if (!url) {
                                alert('Failed to get download URL.');
                                return;
                            }

                            // Create a temporary link to download the file
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', fileName); // Optional: set filename for download
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
        }
    },
    mounted() {
        this.getProviderList()
        this.getReportData()
    },
    computed: {

    }
}
</script>