<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
        <div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 px-0">
            <p class="cmn_contxt mb-10 pl-50 mt-30">
                <b>Sentient EMR will seamlessly register e-results / HL7 with Alpha Labs, Dynacare, and Lifelabs. Please find the status update below.</b>
            </p>
            <p class="cmn_contxt">
                <ul class="pl-100">
                    <li><b>Processing:</b> We are actively preparing the e-result registration request.</li>
                    <li><b>Pending:</b> The initial registration request has been submitted and is awaiting a response from the lab. </li>
                    <li><b>Completed:</b> The e-result / HL7 configuration is finalized. All e-result / HL7 updates will now be updated daily in the File module</li>
                </ul>
                
            </p>

            <div class="admin-left-content management_settings">
                <div class="table-responsive acct-stng-tbl shift-hr-setting-tbl">
                    <table class="table table-borderless text-center mb-0 ">
                        <thead>
							<tr>
								<th class="text-center">Provider Name</th>
								<th class="text-center" :class="{ 'active': this.orderBy == 'updated_datetime' }" @click="changeSort('updated_datetime')">Last Updated On <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder== 'asc' && this.orderBy == 'updated_datetime' }"></th>
								<th class="text-center" :class="{ 'active': this.orderBy == 'alpha_lab_status' }" @click="changeSort('alpha_lab_status')">Alpha Labs <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder== 'asc' && this.orderBy == 'alpha_lab_status' }"></th>
								<th class="text-center" :class="{ 'active': this.orderBy == 'dynacare_status' }" @click="changeSort('dynacare_status')">Dynacare <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder== 'asc' && this.orderBy == 'dynacare_status' }"></th>
								<th class="text-center" :class="{ 'active': this.orderBy == 'life_lab_status' }" @click="changeSort('life_lab_status')">Lifelabs <img src="/images/down-arrow-new.svg" class="img-fluid" :class="{ 'rotate180': this.sortOrder== 'asc' && this.orderBy == 'life_lab_status' }"></th>
							</tr>
						</thead>
                        <tbody v-if="eResultData.length">
                            <tr v-for="(item,index) in eResultData" :key="index">
                                <td class="text-center">HONG, JAMES</td>
                                <td class="text-center">{{ $filters.inputDateTime(item.updated_datetime,
										'YYYY-MM-DD')  }}</td>
                                <td class="text-center">{{ item.alpha_lab_status }}</td>
                                <td class="text-center">{{ item.dynacare_status }}</td>
                                <td class="text-center">{{ item.life_lab_status }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sidebar from './sidebar.vue'
import axios from "@/scripts/axios.js";
export default {
	data(){
		return{
            eResultData:[],
            sortOrder: '',
			orderBy: '',
        }
    },
    components:{
		sidebar,
	},
    methods:{
        getReportData(){
            this.$store.state.loader = true;
            axios.post('admin/eResult-config/list', {
                    sortOrder: this.sortOrder,
                    orderBy:this.orderBy,
                })
                .then((response) => {
                    this.$store.state.loader = false;
                    if (response.status == 200) {
                        this.eResultData = response.data.data
                    } else {
                        this.$filters.moshaToast(response.data.message, "error");
                    }
                }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else if(error.response.status === 402){
                        window.location.href = error.response.data.redirect_url;
                    } else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
        },
        changeSort(fieldName){
			if (fieldName != this.orderBy) {
				this.sortOrder= 'desc'
			} else if (this.sortOrder== 'desc') {
				this.sortOrder= 'asc'
			} else{
				this.sortOrder= 'desc'
			}

			this.orderBy = fieldName

			this.getReportData();
		},
    },
    mounted(){
        this.getReportData()
    }
}
</script>