<template>
	<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 left_side_bar e_chart_sidebar px-0" v-once>
		<div class="sticky-scroll-box">
			<ul class="side-bar-menu" data-widget="tree">
				<li :class="{ 'active': this.$route.name === 'AccountSettings' }" >
                    <router-link :to="{ name: 'AccountSettings'}"> Account Settings</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'AccountSettings'">
				</li>
				<li :class="{ 'active': this.$route.name === 'clinicSettings' }" >
                    <router-link :to="{ name: 'clinicSettings'}"> Clinic Settings</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'clinicSettings'">
				</li>
				<li :class="{ 'active': this.$route.name === 'managementSettings' }" >
                    <router-link :to="{ name: 'managementSettings'}"> Management Settings</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'managementSettings'">
				</li>
				<li :class="{ 'active': this.$route.name === 'hrAnalysisReports' }" >
                    <router-link :to="{ name: 'hrAnalysisReports'}"> HR Analysis Report</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'hrAnalysisReports'">
				</li>
				<li :class="{ 'active': this.$route.name === 'billingCustomCode' }" >
                    <router-link :to="{ name: 'billingCustomCode'}"> Billing Custom Codes</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'billingCustomCode'">
				</li>
				<li :class="{ 'active': this.$route.name === 'thirdPartyIntegrations' }" >
                    <router-link :to="{ name: 'thirdPartyIntegrations'}"> Third-Party Integrations</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'thirdPartyIntegrations'">
				</li>
				<li>
					<a :href="this.paymentPrefLink" target="_blank"> Payment Preferences</a>
				</li>
				<li :class="{ 'active': this.$route.name === 'auditLogs' }" >
                    <router-link :to="{ name: 'auditLogs'}"> Audit Logs</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'auditLogs'">
				</li>
				<li :class="{ 'active': this.$route.name === 'dataManagement' }" >
                    <router-link :to="{ name: 'dataManagement'}"> Data Management</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'dataManagement'">
				</li>
				<li :class="{ 'active': this.$route.name === 'eResultConfig' }" >
                    <router-link :to="{ name: 'eResultConfig'}"> eResult Configuration</router-link>
					<img src="/images/down-arrow_r_new.svg" alt="" class="img-fluid" v-if="this.$route.name === 'eResultConfig'">
				</li>
            </ul>
        </div>
    </div>
</template>
<script>
import axios from "@/scripts/axios.js";

export default {
	data() {
		return {
			paymentPrefLink: null
		}
	},
	mounted(){
		this.getPaymentLink()
	},
	methods:{
		getPaymentLink(){
			axios.get('stripe/get-payment-link')
				.then((response) => {
					this.$store.state.loader = false;
					if (response.status == 200) {
						let responseDetail = response.data.data;
						this.paymentPrefLink = responseDetail.redirect_url
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                        console.log(Object.values(error.response.data.data).join(', '))
                    } else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                });
		}
	},
	computed: {
	}
}
</script>
