<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 px-0">
			<div class="clinic-location-main">
				<div class="mb-40 d-flex align-items-center">
					<div class="main-head-ttl">
						<h2 class="h1-admin_head mb-0">Clinic Location Settings</h2>
					</div>
					<div class="clnc-edt-save-sect">
						<span class="popup_link fw-600 ml-75" @click="editForm" v-if="!this.isShowEditingForm" :class="{ 'invisible': !this.checkPermission()}">Edit</span>
						<div class="text-end float-end">
							<span class="popup_link mr-60 fw-600" @click="cancelForm" v-if="this.isShowEditingForm">Cancel</span>
							<span class="popup_link fw-600" v-if="this.isShowEditingForm" @click="save()">Save</span>
						</div>
					</div>
				</div>
				<div class="clinic-location-sttigs" v-if="!this.isShowEditingForm">
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">Clinic ID</p>
							</div>
						</div>
						<div class="clnc-save-right">
							<div class="edit-fild-nw">
								<p class="mb-0">{{this.form.clinic_unique_id}}</p>
							</div>
						</div>
					</div>
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">Location Name</p>
							</div>
						</div>
						<div class="clnc-save-right">
							<div class="edit-fild-nw">
								<p class="mb-0">{{this.form.location_name}}</p>
							</div>
						</div>
					</div>
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">Address</p>
							</div>
						</div>
						<div class="clnc-save-right align-middle">
							<div class="edit-fild-nw">
								<p class="mb-0 max-demographic-cont">{{this.form.address}}</p>
							</div>
						</div>
					</div>
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">City</p>
							</div>
						</div>
						<div class="clnc-save-right">
							<div class="edit-fild-nw">
								<p class="mb-0">{{this.form.city}}</p>
							</div>
						</div>
					</div>
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">Province</p>
							</div>
						</div>
						<div class="clnc-save-right">
							<div class="edit-fild-nw">
								<p class="mb-0">{{this.form.province}}</p>
							</div>
						</div>
					</div>
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">Postal</p>
							</div>
						</div>
						<div class="clnc-save-right">
							<div class="edit-fild-nw">
								<p class="mb-0">{{this.form.postal}}</p>
							</div>
						</div>
					</div>
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">Telephone</p>
							</div>
						</div>
						<div class="clnc-save-right">
							<div class="edit-fild-nw">
								<p class="mb-0">{{this.form.phone_number}}</p>
							</div>
						</div>
					</div>
					<div class="mb-15 d-flex align-items-center">
						<div class="clnc-head-lft">
							<div class="clcl-loctn-st-ttl">
								<p class="mb-0">Fax</p>
							</div>
						</div>
						<div class="clnc-save-right">
							<div class="edit-fild-nw">
								<p class="mb-0">{{this.form.fax_number}}</p>
							</div>
						</div>
					</div>
				</div>
				<div class="" v-if="this.isShowEditingForm">
					<div class="clinic-location-sttigs">
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">Clinic ID</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.clinic_unique_id" :readonly="this.form.id">
									<ValidationErrorMessageList :errors="v$.form.clinic_unique_id.$errors" />
								</div>
							</div>
						</div>
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">Location Name</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.location_name">
									<ValidationErrorMessageList :errors="v$.form.location_name.$errors" />
								</div>
							</div>
						</div>
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">Address</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw auto_input" :data-value="this.form.address">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.address">
								</div>
								<ValidationErrorMessageList :errors="v$.form.address.$errors" />
							</div>
						</div>
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">City</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.city">
									<ValidationErrorMessageList :errors="v$.form.city.$errors" />
								</div>
							</div>
						</div>
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">Province</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.province">
									<ValidationErrorMessageList :errors="v$.form.province.$errors" />
								</div>
							</div>
						</div>
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">Postal</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.postal">
									<ValidationErrorMessageList :errors="v$.form.postal.$errors" />
								</div>
							</div>
						</div>
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">Telephone</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.phone_number" @input="formatPhoneNumber('phone_number')">
									<ValidationErrorMessageList :errors="v$.form.phone_number.$errors" />
								</div>
							</div>
						</div>
						<div class="mb-15 d-flex">
							<div class="clnc-head-lft">
								<div class="clcl-loctn-st-ttl mt-7">
									<p class="mb-0">Fax</p>
								</div>
							</div>
							<div class="clnc-head-rit">
								<div class="edit-fild-nw">
									<input type="text" placeholder="" class="form-control cursor-text" v-model="this.form.fax_number" @input="formatPhoneNumber('fax_number')">
									<ValidationErrorMessageList :errors="v$.form.fax_number.$errors" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="mb-30">
					<div class="sentient-ai-ttl">
						<p class="mb-0 text-center pe-0">Sentient AI API Key</p>
					</div>
					<div class="sntnt-ai-api-inpt d-inline-block">
						<div class="d-flex align-items-center">
							<input type="text" placeholder="" class="form-control cursor-text" v-model="this.textToCopy" readonly>
							<span class="popup_link fw-600 fs-18" style="margin-left: calc(var(--scale-ratio) * 45px);" @click="copyText">Copy</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import sidebar from './sidebar.vue'
	import axios from "@/scripts/axios.js";
	import useVuelidate from "@vuelidate/core";
	import ValidationErrorMessageList from '../base/ValidationErrorMessageList.vue';

	export default {
		setup: () => {
			return { v$: useVuelidate() };
		},
		data(){
			return{
				isShowEditingForm:false,
				loader:false,
				textToCopy:'avcdeffffhgighih123123123',
				permissionList:[]
			}
		},
		components:{
			sidebar,
			ValidationErrorMessageList
		}, 
		methods:{
			editForm(){
				this.isShowEditingForm = true
			},
			cancelForm(){
				this.fetchUserSiteDetail()
				this.isShowEditingForm = false
			},
			fetchUserSiteDetail(){
				this.$store.state.loader = true;
				axios.post('admin/user-site/retrieve')
                    .then((response) => {
                        this.$store.state.loader = false;
                        if (response.status == 200) {
							if (Array.isArray(response.data.data) && response.data.data.length === 0) {
								this.$store.state.user_site_details.form = this.$store.state.user_site_details.defaultFormData;
							} else {
								this.$store.state.user_site_details.form = response.data.data;
							}
                        } else {
                            this.$filters.moshaToast(response.data.message, "error");
                        }
                    }).catch(error => {
                    this.$store.state.loader = false;
                    if (error.response.status === 422) {
                        this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
                    } else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
                        this.$filters.moshaToast(error.response.data.message, "error");
                    }
                })
			},
			save(){
				let $this = this
				this.loader = true
				let formName = 'form'
				this.v$[formName].$validate();

				if (!this.v$[formName].$error) {
					let url = "admin/user-site/save";
					axios.post(url, this.form)
					.then((response) => {
						if (response.status == 200) {
							this.loader = false
							this.fetchUserSiteDetail()
							this.isShowEditingForm = false
							setTimeout(function () {
								$this.$filters.moshaToast("Clinic Settings has been updated successfully", "success");	
							}, 400);
						} else {
							this.loader = false
							this.$filters.moshaToast(response.data.message, "error")
						}
					})
				} else {
					for (const [key] of Object.entries(this.v$.form)) {
						if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
							const myEl = document.getElementById(key);
							if (myEl) {
								this.$smoothScroll({
									scrollTo: myEl,
								});
								break;
							}
						}
					}
				}
			},
			formatPhoneNumber(fieldName) {
				let phoneNumber = null
				if (fieldName == 'phone_number') {
					phoneNumber = this.form.phone_number
				} else if (fieldName == 'fax_number'){
					phoneNumber = this.form.fax_number
				}
				// // Remove all non-numeric characters
				let cleaned = ('' + phoneNumber).replace(/\D/g, '');

				// // Match the cleaned string to the desired format
				let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

				if (match) {
					phoneNumber = `${match[1]}-${match[2]}-${match[3]}`;
				} else {
					phoneNumber = cleaned;
				}

				if (fieldName == 'phone_number') {
					this.form.phone_number = phoneNumber
				} else if (fieldName == 'fax_number'){
					this.form.fax_number = phoneNumber
				}
			},
			async copyText() {
				try {
					await navigator.clipboard.writeText(this.textToCopy);
					this.$filters.moshaToast('Text copied to clipboard!', "success")
				} catch (err) {
					this.$filters.moshaToast('Failed to copy text!', "success")
				}
			},
			getPermissionList(){
                axios.post('user/get-permission')
                .then((response) => {
                    if (response.status == 200) {
                        this.permissionList = response.data.data
                    } else {
                        this.permissionList = []
                    }
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.permissionList = []
                    } else {
                        this.permissionList = []
                    }
                });
            },
            checkPermission(){
				if (!this.permissionList) {
					this.getPermissionList()
				}
                if (this.permissionList && this.permissionList.administration_access) {
                    return true
                } else {
                    return false
                }
            },
		},
		mounted(){
			this.getPermissionList()
			this.fetchUserSiteDetail()
		},
		computed: {
			form() {
				return this.$store.state.user_site_details.form;
			},
		},
		validations() {
			return this.$store.state.user_site_details.validationRules;
		},

	};
</script>